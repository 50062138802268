import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dates: {
        dates: [],
        formatted_dates: [],
    },
    selectedDate: '',
    reportsData: {},
    isLoading: false,
    error: null,
};

export const ReportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
            state.error = null;
            state.reportsData = {};
        },
        setReportDates: (state, action) => {
            state.dates = action.payload;
            state.isLoading = false;
            state.selectedDate = state.dates.dates.length > 0 ? state.dates.dates[0] : null;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setReportContent: (state, action) => {
            const { date, data } = action.payload;
            state.reportsData[date] = data;
            state.isLoading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    startLoading,
    setReportDates,
    setSelectedDate,
    setReportContent,
    setError,
} = ReportsSlice.actions;

export default ReportsSlice.reducer;
