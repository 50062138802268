import React, { lazy } from 'react';

import Loadable from '../layouts/shared/loadable/Loadable';

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const ChatPage = Loadable(lazy(() => import('src/pages/Chat/AgentPage')));
const PDFReportPage = Loadable(lazy(() => import('src/pages/Report/PDFReport')));
const SignalsPage = Loadable(lazy(() => import('src/pages/Signals/SignalsPage')));

const Router = [{
    path: '/',
    element: <FullLayout />,
    children: [
        { path: '/chat', exact: true, element: <ChatPage /> },
        { path: '/reports', exact: true, element: <PDFReportPage /> },
        { path: '/signals', exact: true, element: <SignalsPage /> },
    ],
}
];

export default Router;
