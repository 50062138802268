import {configureStore} from '@reduxjs/toolkit';
import CustomizerReducer from './CustomizerSlice';
import NotificationReducer from './NotificationSlice';
import ChatReducer from './ChatSlice';
import PostsReducer from './postsSlice';
import ReportsReducer from './ReportSlice';
export const store = configureStore({
    reducer: {
        notificationReducer: NotificationReducer,
        customizer: CustomizerReducer,
        chatReducer: ChatReducer,
        postsReducer: PostsReducer,
        reportsReducer: ReportsReducer,
    },
});

export default store;
