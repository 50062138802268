import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    messages: null,
    session_id: null,
    is_loading: false,
};

export const ChatSlice = createSlice({
        name: 'messages',
        initialState,
        reducers: {
            setAgentChatMessages:(state, action) => {
                state.messages = action.payload;
            },
            resetAgentChatMessages: (state, action) => {
                state.messages = [];
            },
            setAgentSessionId: (state, action) => {
                console.log("Setting agent session id : " + action.payload)
                state.session_id = action.payload;
            },
            setAgentChatLoading: (state, action) => {
                state.is_loading = action.payload;
            },
            addNewMessage: (state, action) => {
                const message = action.payload;
                state.messages.push(message);
            },
            addAgentChatMessageDelta: (state, action) => {
                const delta = action.payload;
                const lastIndex = state.messages.length - 1;
                console.log("Last index : " + lastIndex)
                console.log("Adding delta to last message : " + delta)
                if (lastIndex >= 0 && state.messages[lastIndex].sender === 'agent') {
                    state.messages[lastIndex].message += delta;
                } else {
                }
            },
        },
    })
;

export const {
    addNewMessage,
    resetAgentChatMessages,
    setAgentChatLoading,
    setAgentSessionId,
    addAgentChatMessageDelta,
    setAgentChatMessages
} = ChatSlice.actions;

export default ChatSlice.reducer;
