const DarkThemeColors = [
    {
        name: 'BLUE_THEME',
        palette: {
            primary: {
                main: '#103fcf',
                light: 'rgb(14, 28, 55)',
                dark: '#0f3cb6',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#49BEFF',
                light: '#1C455D',
                dark: '#23afdb',
                contrastText: '#ffffff',
            },
            third: {
                main: '#071a55',
                light: '#1C455D',
                dark: '#23afdb',
                contrastText: '#ffffff',
            },
            background: {
                default: '#000000',
                dark: '#000000',
                paper: '#000000',
            },
        },
    },

];

export { DarkThemeColors };
