// feedSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    live_feed: [],
    page: 0,
    has_more: true,
    selectedPost: {},
    scrollPosition: 0,
    expandedFeeds: [],
}

export const postsSlice = createSlice({
    name: 'feed',
    initialState,
    reducers: {
        setFeed(state, action) {
            state.live_feed = action.payload;
        },
        setPage(state, action) {
            state.page = action.payload;
        },
        setScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
        setFeedExpanded: (state, action) => {
            if (state.expandedFeeds.includes(action.payload)) {
                state.expandedFeeds = state.expandedFeeds.filter(id => id !== action.payload);
            } else {
                state.expandedFeeds.push(action.payload);
            }
        },
        selectFeedByID: (state, action) => {
            const feed = state.live_feed.find(feed => feed.id === action.payload);
            if (feed) {
                state.selectedPost = feed;
            } else {
                state.selectedPost = {};
            }
        },
        appendFeeds: (state, action) => {
            action.payload.forEach(feed => {
                state.live_feed.push(feed);
            });
        },
        prependFeed: (state, action) => {
            state.live_feed = [...action.payload, ...state.live_feed];
        },
        setHasMore: (state, action) => {
            state.has_more = action.payload;
        },
        resetFeeds: (state) => {
            state.live_feed = [];
        },

    },
});


export const {
    setFeed,
    setPage,
    setScrollPosition,
    setHasMore,
    selectFeedByID,
    appendFeeds,
    resetFeeds,
    setFeedExpanded,
} = postsSlice.actions;

export default postsSlice.reducer
