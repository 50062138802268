// createSupabaseWrapper.js
import { createClient } from '@supabase/supabase-js';
import { showNotification } from "src/store/NotificationSlice";

const supabaseUrl = "https://meqmlmodqnmyjgjnrikg.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1lcW1sbW9kcW5teWpnam5yaWtnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDM2MTE0NDUsImV4cCI6MjAxOTE4NzQ0NX0.nFaEFe5Sk-CcxOBhgcOZ5o_voZYE3EsxlwpcCEgg_TI";

const supabase = createClient(supabaseUrl, supabaseAnonKey);

const handleSupabaseError = (dispatch, response) => {
  const { error } = response;

  let showNotify = true;
  if (typeof response[response.length - 1] === 'boolean') {
    showNotify = response.pop();
  }

  if (error && showNotify) {
    if (error.code === '42703') {
      dispatch(showNotification({
        severity: 'error',
        title: 'Database Error',
        message: `The requested column does not exist: ${error.message}`
      }));
    } else {
      dispatch(showNotification({
        severity: 'error',
        title: 'Fail',
        message: error.message+' - '+error.details
      }));
    }
  }

  return response;
};


export { handleSupabaseError, supabase };
